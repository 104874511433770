import Rails from '@rails/ujs'
import { ComponentPublicInstance, createApp } from 'vue'
import type { App } from 'vue'
import type { SortableEvent } from "sortablejs";
import i18next from "i18next";
import I18NextVue from 'i18next-vue';

interface InitCB {
  (app: App<Element>): void;
}

export function initVue2(
  component: any,
  el: Element,
  props?: Record<string, any>,
  beforeMountCB?: InitCB
  ): ComponentPublicInstance {

  const app = createApp(component, props || {})
  app.use(I18NextVue, {i18next});
  if (beforeMountCB) {
    beforeMountCB(app)
  }
  return app.mount(el)
}

export function isBlank(value: any): value is null | undefined {
  if (value === null || typeof value === 'undefined') {
    return true
  }
  if (typeof value === 'string' && (value.length === 0 || /^\s*$/.test(value))) {
    return true
  }

  return !(value || false)
}

// fixme: don't use object
// eslint-disable-next-line @typescript-eslint/ban-types
export function isPresent(value: any): value is object {
  return !isBlank(value)
}

export function isNonEmptyString(value: any): value is string {
  return typeof value === 'string' && value.length > 0 && /^\s*$/.test(value) == false
}

export function isEmptyOrNotString(value: any): boolean {
  return !isNonEmptyString(value)
}

export function getValue(value: any, defaultValue: any): any {
  if (isPresent(value)) {
    return value
  }

  return defaultValue || null
}

export function getFloat(value: string | number | null | undefined): number | null {
  if (typeof value === 'number') {
    if (isNaN(value)) {
      return null
    }
    return value
  }

  if (isNonEmptyString(value)) {
    const v = parseFloat(value)
    if (!isNaN(v)) {
      return v
    }
  }
  return null
}

export function getInt(value: string | number | null | undefined): number | null {
  if (value == null) {
    return null
  }

  if (typeof value === 'number') {
    if (isNaN(value)) {
      return null
    }
    return value
  }

  const v = parseInt(value)
  if (isNaN(v)) {
    return null
  }
  return v
}

export function simulateFormPost(url: string, params?: Record<string, any>): void {
  const csrfToken = Rails.csrfToken()
  const csrfParam = Rails.csrfParam()

  const formEl = document.createElement('form');
  formEl.method = 'POST'
  formEl.action = url
  formEl.style.display = 'none'

  const methodInputEl = document.createElement('input')
  methodInputEl.type = 'hidden'
  methodInputEl.name = '_method'
  methodInputEl.value = 'POST'
  formEl.append(methodInputEl)

  if (!Rails.isCrossDomain(url) && csrfParam != null && csrfToken != null) {
    const csrfInputEl = document.createElement('input')
    csrfInputEl.type = 'hidden'
    csrfInputEl.name = csrfParam
    csrfInputEl.value = csrfToken
    formEl.append(csrfInputEl)
  }

  if (params != null) {
    for (let paramName of Object.keys(params)) {
      let values = (params as any)[paramName]
      if (Array.isArray(values)) {
        paramName = `${paramName}[]`
      }

      values = Array.isArray(values) ? values : [values]
      for (const val of values) {
        const paramEl = document.createElement('input')
        paramEl.type = 'hidden'
        paramEl.name = paramName
        paramEl.value = val
        formEl.append(paramEl)
      }
    }
  }

  const submitEl = document.createElement('input')
  submitEl.type = 'submit'
  formEl.append(submitEl)

  document.body.appendChild(formEl);
  submitEl.click()
}

export function getAxiosErrorMessage(error: any, defaultMessage?: string): string {
  let errorMessage = null
  if (error?.response?.data) {
    const errors = error.response.data.errors
    if (Array.isArray(errors) && errors.length > 0 && isNonEmptyString(errors[0].title)) {
      errorMessage = errors[0].title
    }

    if (isEmptyOrNotString(errorMessage)) {
      // sometimes response has a json with an error key
      if (isNonEmptyString(error.response.data.error)) {
        errorMessage = error.response.data.error
      }
    }
  }

  if (isEmptyOrNotString(errorMessage)) {
    errorMessage = defaultMessage
  }

  if (isEmptyOrNotString(errorMessage)) {
    if (error && isNonEmptyString(error.message)) {
      errorMessage = error.message
    } else {
      errorMessage = 'An error occured'
    }
  }
  return errorMessage
}

const toSnakeCase = (str: string): string => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)

export function transformParams(params: Record<string, any> | any[] | any | null | undefined): Record<string, any> | any[] | any | null {
  if (params == null || typeof params === 'undefined') {
    return null
  }

  if (typeof params !== 'object') {
    return params
  }

  if (Array.isArray(params)) {
    return params.map(x => transformParams(x))
  }

  const result = {} as Record<string, any>
  for (const pKey in params) {
    if (params.hasOwnProperty(pKey)) {
      // formData.append(pKey, params[pKey])
      const sKey = toSnakeCase(pKey)
      let val = params[pKey]

      if (typeof val === 'object') {
        // convert arrays/objects
        val = transformParams(val)
      }
      result[sKey] = val
    }
  }

  return result
}

export function sortSortableList(list: any[], event?: SortableEvent) {
  if (event?.newIndex != null && event?.oldIndex != null) {
    list.splice(event.newIndex, 0, list.splice(event.oldIndex, 1)[0])
  }
}
